<template>
  <div class="eggsbook">
    <loader />
    <div class="content-page">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import loader from '@/components/Loader.vue';

export default {
  components: {
    loader,
  },
  data() {
    return {
      addressCurrent: '',
      isConnected: false,
    };
  },
  mounted() {
  },
  methods: {},
};
</script>

<style lang="scss">
.eggsbook {
  overflow-x: hidden;
}
.content-page {
}
</style>
