const axios = require('@/store/config/axios.config').default;

export default {
  namespaced: true,
  state: () => ({
    listCurrency: [],
  }),

  getters: {
    ListCurrency: (state) => state.listCurrency,
  },

  actions: {
    req_getListCurrency({ commit }) {
      axios.get('license/list-currency').then((res) => {
        if (res) {
          commit('SET_LIST_CURRENCY', res);
        }
      });
    },
    req_postSendContact({ commit }, input) {
      axios.post('license/send', input).then((res) => {
        if (res) {
          commit('SEND_SUCCESS', res);
        }
      });
    },
  },

  mutations: {
    SET_LIST_CURRENCY(state, data) {
      state.listCurrency = data.data;
    },
    SEND_SUCCESS() { },
  },
};
