import Vue from 'vue';
import Vuex from 'vuex';

import moduleCore from './config/core';
import license from './license';

Vue.use(Vuex);

export default new Vuex.Store({
  state: () => ({
    loadCount: 0,
    isLoad: false,
    totalSend: 0,
    totalReceive: 0,
    error: '',
    success: '',
    status: 0,
  }),
  getters: {
    LoadingStatus: (state) => state.loadingStatus,
  },
  mutations: {
    onLoad(state) {
      state.loadCount += 1;
      state.isLoad = true;
    },
    outLoad(state) {
      state.loadCount -= 1;
      if (state.loadCount <= 0) {
        state.isLoad = false;
        state.loadCount = 0;
      }
    },
    StatusLoad: (state, data) => {
      if (data) {
        state.loadingStatus = true;
        return;
      }
      state.loadingStatus = false;
      state.totalSend = 0;
      state.totalReceive = 0;
    },
  },
  actions: {},
  modules: {
    core: moduleCore,
    license,
  },
});
